"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var use_stores_1 = require("hooks/use-stores");
var UserStore_1 = require("stores/UserStore");
var Calendar_1 = require("../Calendar");
var NotePreview = function (_a) {
    var note = _a.note, editing = _a.editing;
    var _b = use_stores_1.useStores(), userStore = _b.userStore, prefsStore = _b.prefsStore;
    var calendarView = prefsStore.data.calendarView;
    var theme = userStore.data.settings.generalSettings.theme;
    var windowRef = react_1.useRef(null);
    var _c = react_1.useState({
        top: "120%",
        left: "-15%",
        right: null,
        bottom: null,
    }), windowStyle = _c[0], setWindowStyle = _c[1];
    var getPosition = function () {
        var previewWindow = document.querySelector(".preview-note__window[data-id=\"" + note.id + "\"]");
        var calendarContainer = document.querySelector("#calendar-container");
        var calendarContainerRect = calendarContainer.getBoundingClientRect();
        var previewWindowRect = previewWindow.getBoundingClientRect();
        if (calendarView === Calendar_1.CalendarViews.MONTH) {
            var newStyle = {
                top: "120%",
                left: "-15%",
                right: null,
                bottom: null,
            };
            if (previewWindowRect.left < calendarContainerRect.left) {
                newStyle.left = "-1%";
            }
            if (previewWindowRect.right > calendarContainerRect.right) {
                newStyle.left = "unset";
                newStyle.right = "20%";
            }
            if (previewWindowRect.bottom > calendarContainerRect.bottom) {
                newStyle.top = "unset";
                newStyle.left = "-7%";
                newStyle.bottom = "120%";
            }
            setWindowStyle(newStyle);
        }
    };
    react_1.useEffect(function () {
        getPosition();
        var calendarContainer = document.querySelector("#calendar-container");
        calendarContainer.addEventListener('scroll', getPosition);
        return function () {
            calendarContainer.removeEventListener('scroll', getPosition);
        };
    }, []);
    return (!editing &&
        react_1.default.createElement("div", { className: 'preview-note__window', ref: windowRef, style: windowStyle, "data-light-theme": theme === UserStore_1.Themes.LIGHT, "data-id": note.id },
            react_1.default.createElement("p", null, note.noteText)));
};
exports.default = NotePreview;
