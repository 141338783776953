"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var use_stores_1 = require("hooks/use-stores");
var UserStore_1 = require("stores/UserStore");
var Calendar_1 = require("../Calendar");
var BookingPreview = function (_a) {
    var description = _a.description, editing = _a.editing, id = _a.id;
    var _b = use_stores_1.useStores(), userStore = _b.userStore, bookingStore = _b.bookingStore, bookingInfoStore = _b.bookingInfoStore, prefsStore = _b.prefsStore;
    var calendarView = prefsStore.data.calendarView;
    var theme = userStore.data.settings.generalSettings.theme;
    var data = bookingInfoStore.data;
    var windowRef = react_1.useRef(null);
    var textAreaRef = react_1.useRef(null);
    var _c = react_1.useState({
        top: "0",
        left: "0",
        bottom: null,
    }), windowStyle = _c[0], setWindowStyle = _c[1];
    var getPosition = function () {
        var previewWindow = document.querySelector(".preview-booking__window[data-id=\"" + id + "\"]");
        var calendarContainer = document.querySelector("#calendar-container");
        var calendarContainerRect = calendarContainer.getBoundingClientRect();
        var previewWindowRect = previewWindow.getBoundingClientRect();
        if (calendarView === Calendar_1.CalendarViews.WEEK) {
            setWindowStyle({
                top: "-50%",
                left: "-130%",
            });
            previewWindowRect.left < calendarContainerRect.left ?
                setWindowStyle({
                    left: "110%"
                })
                : null;
            previewWindowRect.bottom > calendarContainerRect.bottom ?
                setWindowStyle({
                    top: "-170%",
                })
                : null;
            previewWindowRect.top < calendarContainerRect.top ?
                setWindowStyle({
                    top: "110%"
                })
                : null;
        }
        if (calendarView === Calendar_1.CalendarViews.MONTH) {
            setWindowStyle({
                top: "120%",
                left: "-8%",
            });
            previewWindowRect.left < calendarContainerRect.left ?
                setWindowStyle({
                    left: "-1%"
                })
                : null;
            previewWindowRect.bottom > calendarContainerRect.bottom ?
                setWindowStyle({
                    top: "unset",
                    left: "-7%",
                    bottom: "120%"
                })
                : null;
        }
        if (calendarView === Calendar_1.CalendarViews.DAY) {
            setWindowStyle({
                top: "15%",
                left: "15%",
            });
        }
    };
    react_1.useEffect(function () {
        var calendarContainer = document.querySelector("#calendar-container");
        calendarContainer.addEventListener('scroll', getPosition);
        return function () {
            calendarContainer.removeEventListener('scroll', getPosition);
        };
    }, []);
    react_1.useEffect(function () {
        if (windowRef.current) {
            getPosition();
        }
        if (textAreaRef.current) {
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
        }
    }, [windowRef.current]);
    react_1.useEffect(function () {
        var animateOut = "scale-fade-out 180ms cubic-bezier(0.215, 0.61, 0.355, 1) forwards";
        if (windowRef.current && editing) {
            windowRef.current.style.animation = animateOut;
        }
    }, [editing]);
    return (react_1.default.createElement("div", { className: 'preview-booking__window', ref: windowRef, style: windowStyle, "data-light-theme": theme === UserStore_1.Themes.LIGHT, "data-id": id },
        react_1.default.createElement("div", { className: 'preview-booking__window__content flex flex-column' },
            react_1.default.createElement("textarea", { disabled: true, ref: textAreaRef, value: description }))));
};
exports.default = BookingPreview;
