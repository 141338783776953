"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    busyDelay: 500,
    appName: 'RWD Book',
    genericError: 'Something went wrong, please try again',
    apiBase: 'https://bookapi.rwd.group',
    apiRoutes: {
        LOGIN: '/login',
        LOGOUT: '/logout',
        FORGOT_PASSWORD: '/forgottenPassword',
        RESET_LINKS: '/resetLinks',
        RESET_PASSWORD: '/resetPassword',
        USER: '/users/self',
        CHANGE_PASSWORD: '/changePassword',
        BOOKINGS: '/bookings',
        ORGANISATIONS: '/organisations',
        CALENDAR_NOTES: '/calendarNotes'
    },
    storageKeys: {
        USER: 'RWD_BOOK_CMS_USER',
        PREFS: 'RWD_BOOK_CMS_PREFS'
    },
    userRoles: {
        USER: 'ROLE_USER',
        ADMIN: 'ROLE_ADMIN',
        SUPER_ADMIN: 'ROLE_SUPER_ADMIN'
    }
};
